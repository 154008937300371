.section-hero {
  .section-content {
    position: relative;
  }

  .headline {
    position: absolute;
    top: 8.2292vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    @include mobile {
      top: 17.6vw;
    }

    .title {
      width: 47.9167vw;

      @include mobile {
        width: 60.5333vw;
      }

      img {
        width: 100%;
      }
    }
  }

  .content {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
    }

    .hero-link {
      position: absolute;
      z-index: 1;

      &_concept {
        top: 67%;
        left: 74%;
        transform: translate(-50%, -50%);
        width: 26vw;
        height: 22vw;
        clip-path: polygon(37% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);

        @include mobile {
          top: 68%;
          left: 81%;
          width: 35vw;
          height: 29vw;
        }
      }

      &_about {
        top: 35%;
        left: 55%;
        transform: translate(-50%, -50%);
        width: 28vw;
        height: 14vw;
        z-index: 2;

        @include mobile {
          top: 37%;
          left: 57%;
          width: 34vw;
          height: 18vw;
        }
      }

      &_beauty {
        top: 23%;
        left: 58%;
        transform: translate(-50%, -50%);
        width: 27vw;
        height: 14vw;
        z-index: 2;

        @include mobile {
          top: 26%;
          left: 61%;
          width: 34vw;
          height: 18vw;
        }
      }

      &_pet {
        top: 45%;
        left: 74%;
        transform: translate(-50%, -50%);
        width: 24vw;
        height: 28vw;
        clip-path: polygon(
          39% 0,
          67% 0,
          100% 30%,
          100% 76%,
          66% 95%,
          11% 94%,
          0 73%,
          11% 36%
        );
        z-index: 2;

        @include mobile {
          top: 47%;
          left: 81%;
          width: 32vw;
          height: 36vw;
        }
      }

      &_support {
        top: 38%;
        left: 26%;
        transform: translate(-50%, -50%);
        width: 27vw;
        height: 23vw;

        @include mobile {
          top: 41%;
          left: 19%;
          width: 35vw;
          height: 30vw;
        }
      }

      &_medical {
        top: 57%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 27vw;
        height: 20vw;

        @include mobile {
          top: 59%;
          left: 16%;
          width: 30vw;
          height: 27vw;
        }
      }

      &_introduce {
        top: 88%;
        left: 80%;
        transform: translate(-50%, -50%);
        width: 22vw;
        height: 22vw;

        @include mobile {
          top: 89%;
          left: 87%;
          width: 24vw;
          height: 27vw;
        }
      }

      &_access {
        top: 90.5%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 33vw;
        height: 18vw;

        @include mobile {
          top: 90.5%;
          left: 53%;
          width: 45vw;
          height: 23vw;
        }
      }

      &_antenna-shop {
        top: 75.5%;
        left: 59%;
        transform: translate(-50%, -50%);
        width: 21vw;
        height: 17vw;
        z-index: 3;
        clip-path: polygon(20% 0%, 80% 0%, 100% 40%, 100% 100%, 0 100%, 0 40%);

        @include mobile {
          top: 76%;
          left: 62%;
          width: 26vw;
          height: 24vw;
        }
      }

      &_salon {
        bottom: 0;
        left: 0;
        width: 52vw;
        height: 32vw;
        clip-path: polygon(100% 0, 100% 38%, 46% 100%, 0 100%, 0 30%);
        z-index: 2;

        @include mobile {
          width: 50vw;
          height: 43vw;
        }
      }

      &_company {
        top: 61%;
        left: 41%;
        transform: translate(-50%, -50%);
        width: 23vw;
        height: 23vw;
        clip-path: polygon(53% 1%, 100% 70%, 100% 100%, 0 100%, 0 37%);
        z-index: 2;

        @include mobile {
          top: 63%;
          left: 38%;
          width: 29vw;
          height: 29vw;
        }
      }

      &_contact {
        top: 72%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 20vw;
        height: 15vw;

        @include mobile {
          top: 74%;
          left: 11%;
          width: 25vw;
          height: 20vw;
        }
      }

      &_ec {
        top: 52%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 27vw;
        height: 26vw;
        clip-path: polygon(25% 0%, 75% 0%, 100% 58%, 100% 100%, 0 100%, 0 41%);

        @include mobile {
          top: 54%;
          width: 36vw;
          height: 35vw;
        }
      }
    }
  }
}
