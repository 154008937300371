.section-menu {
  .section-content {
    padding-top: 7.4479vw;
    padding-bottom: 3.2552vw;

    @include mobile {
      padding-top: 47px;
      padding-bottom: 23px;
    }
  }
}
