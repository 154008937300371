.opening {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;

  &__bg {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  &__logo {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;

    @include mobile {
      width: 75%;
    }

    path {
      stroke-width: 1px;
      stroke: #1d2088;
      fill-opacity: 0;
    }

    .main {
      path {
        stroke-dasharray: 700px;
        stroke-dashoffset: 700px;
        stroke-width: 2px;

        @include mobile {
          stroke-width: 2px;
        }
      }
    }

    .r-mark {
      stroke-dasharray: 130px;
      stroke-dashoffset: 130px;
    }
  }
}
